
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination } from "@/libs/utils";
import { Unit } from "@/store/unit/types";

const VUnitList = defineComponent({
  name: "VUnitList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de parceiros | obmed" });

    const store = useStore();
    const route = useRoute();
    const { hasFilters } = useFilter();

    const units = computed(() => store.state.unit.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(unit: Unit) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "unit-edit", params: { _id: String(unit.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "unit-edit", params: { _id: String(unit.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "unit-delete", params: { _id: String(unit.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Parceiros",
        icon: "fas fa-hospital mr-1",
        to: { name: "unit-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "unit",
      setPage: "setUnitPaginationPage",
      updateList: getUnits,
    });

    async function getUnits() {
      loading.list = true;
      await store.dispatch("getUnits");
      loading.list = false;
    }

    getUnits();

    return {
      units,
      pagination,
      loading,
      breadcrumb,
      hasFilters,
      getDropdownOptions,
      handleUpdateCurrentPage,
      getUnits,
    };
  },
});

export default VUnitList;
